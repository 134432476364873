import * as Sentry from '@sentry/react'

const dsn = process.env.NEXT_PUBLIC_SENTRY_DSN
const release = process.env.NEXT_PUBLIC_RELEASE
const canUseSentry = process.env.NEXT_PUBLIC_CAN_USE_SENTRY
const environment = process.env.NEXT_PUBLIC_ENV

Sentry.init({
  enabled: !!canUseSentry,
  release,
  environment,
  dsn,
})

export const captureException = error => {
  if (canUseSentry) return Sentry.captureException(error)

  console.error(error)
}
